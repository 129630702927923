import React from 'react';
import { Invites } from './invites/Invites';
import { Members } from './members/Members';

export function Staff() {
  return (
    <>
      <Invites />
      <Members />
    </>
  );
}
