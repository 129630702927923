export enum ApiScope {
  user_read = 'user_read',
  user_disconnect = 'user_disconnect',
  user_hook_list = 'user.user_hook_list',
  user_hook_create = 'user.user_hook_create',
  user_hook_read = 'user.user_hook_read',
  user_hook_update = 'user.user_hook_update',
  user_hook_delete = 'user.user_hook_delete',
  org_create = 'org_create',
  org_read = 'org_read',
  org_list = 'org_list',
  org_update = 'org_update',
  org_logo_get = 'org.org_logo_get',
  org_logo_set = 'org.org_logo_set',
  org_logo_remove = 'org.org_logo_remove',
  org_hook_list = 'org.org_hook_list',
  org_hook_create = 'org.org_hook_create',
  org_hook_read = 'org.org_hook_read',
  org_hook_update = 'org.org_hook_update',
  org_hook_delete = 'org.org_hook_delete',
  member_get_current = 'member.member_get_current',
  members_list = 'members_list',
  member_delete = 'member_delete',
  member_update = 'member_update',
  invite_add = 'invite_add',
  invite_delete = 'invite_delete',
  invite_list = 'invite_list',
  invite_read = 'invite_read',
  invite_accept = 'invite_accept',
  invite_decline = 'invite_decline',
  team_list = 'team_list',
  team_create = 'team_create',
  team_read = 'team_read',
  team_update = 'team_update',
  team_delete = 'team_delete',
  team_logo_set = 'team.team_logo_set',
  team_logo_remove = 'team.team_logo_remove',
  team_logo_get = 'team.team_logo_get',
  team_member_list = 'team.team_member_list',
  team_member_add = 'team.team_member_add',
  team_member_delete = 'team.team_member_delete',
  team_sites_list = 'team.team_sites_list',
  team_sites_add = 'team.team_sites_add',
  team_hook_list = 'team.team_hook_list',
  team_hook_create = 'team.team_hook_create',
  team_hook_read = 'team.team_hook_read',
  team_hook_update = 'team.team_hook_update',
  team_hook_delete = 'team.team_hook_delete',
  sites_list = 'sites_list',
  site_read = 'site_read',
  site_update = 'site_update',
  site_delete = 'site_delete',
  site_headers_set = 'site.site_headers_set',
  site_logo_set = 'site.site_logo_set',
  site_logo_remove = 'site.site_logo_remove',
  site_logo_get = 'site.site_logo_get',
  site_password_set = 'site.site_password_set',
  site_password_remove = 'site.site_password_remove',
  site_name_validate = 'site.site_name_validate',
  site_branch_add = 'site_branch_add',
  site_branch_read = 'site.site_branch_read',
  site_branch_delete = 'site_branch_delete',
  site_branch_list = 'site.site_branch_list',
  site_branch_name_validate = 'site.branch.site_branch_name_validate',
  site_branch_rename = 'site.site_branch_rename',
  site_branch_release_set = 'site.branch.site_branch_release_set',
  site_branch_password_set = 'site.branch.site_branch_password_set',
  site_branch_password_remove = 'site.branch.site_branch_password_remove',
  site_branch_redirects_read = 'site.branch.site_branch_redirects_read',
  site_branch_redirects_set = 'site.branch.site_branch_redirects_set',
  site_branch_headers_set = 'site.branch.site_branch_headers_set',
  site_hook_list = 'site.site_hook_list',
  site_hook_create = 'site.site_hook_create',
  site_hook_read = 'site.site_hook_read',
  site_hook_update = 'site.site_hook_update',
  site_hook_delete = 'site.site_hook_delete',
  site_releases_list = 'site.site_releases_list',
  release_upload = 'release_upload',
  release_read = 'release_read',
  release_update = 'release_update',
  release_delete = 'release_delete',
}
